import React, { useEffect, useRef } from 'react'
import "./partners.scss"
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import gsap from "gsap";

import { ScrollTrigger } from 'gsap/ScrollTrigger';


gsap.registerPlugin(ScrollTrigger);

const Partners = () => {
    const textRef1 = useRef(null);
    const textRef2 = useRef(null);
    const textRef3 = useRef(null);
    // const textRef4 = useRef(null);

    useEffect(() => {
        const mediaQuery = window.matchMedia('(max-width: 768px)');
        const isMobile = mediaQuery.matches;

        animateText(textRef1, '#6C6C6C', 0.1, isMobile);
        animateText(textRef2, '#fff', 0.1, isMobile);
        animateText(textRef3, '#9A9A9A', 0.03, isMobile);
        // animateText(textRef4, '#fff', 0.1, isMobile);

        const handleMediaChange = (e) => {
            const isMobile = e.matches;
            animateText(textRef1, '#6C6C6C', 0.1, isMobile);
            animateText(textRef2, '#fff', 0.1, isMobile);
            animateText(textRef3, '#9A9A9A', 0.03, isMobile);
            // animateText(textRef4, '#fff', 0.1, isMobile);
        };

        mediaQuery.addListener(handleMediaChange);

        return () => {
            mediaQuery.removeListener(handleMediaChange);
        };
    }, []);

    const animateText = (ref, color, stagger, isMobile) => {
        const chars = ref.current.innerText.split('');
        ref.current.innerHTML = '';

        chars.forEach((char, i) => {
            const charSpan = document.createElement('span');
            charSpan.innerText = char;
            ref.current.appendChild(charSpan);
        });

        gsap.from(ref.current.children, {
            y: 100,
            opacity: 0.2,
            stagger: stagger || 0.1,
            scrollTrigger: {
                trigger: ref.current,
                start: isMobile ? 'top 150%' : 'top 90%',
                end: isMobile ? 'bottom 20%' : 'bottom 50%',
                scrub: true
            }
        });

        gsap.to(ref.current.children, {
            color: color || '#6C6C6C',
            stagger: stagger || 0.1,
            scrollTrigger: {
                trigger: ref.current,
                start: isMobile ? 'top 150%' : 'top 90%',
                end: isMobile ? 'bottom 20%' : 'bottom 50%',
                scrub: true
            }
        });
    };

    const owl_option = {
        loop: true,
        margin: 10,
        nav: false,
        dots: false,
        autoplay: true,
        autoplayTimeout: 1,
        autoplayHoverPause: true,
        smartSpeed: 2000,
        animateOut: 'linear',
        animateIn: 'linear',
        responsive: {
            0: {
                items: 1,
                margin: 10,
            },
            361: {
                items: 1.5,
                margin: 10,
            },
            600: {
                items: 1.5,
                margin: 10,
            },
            700: {
                items: 2.5,
                margin: 10,
            },
            1000: {
                items: 4.5,
                margin: 10,
            },
            1600: {
                items: 5,
                margin: 10,
            },
            1900: {
                items: 7,
                margin: 10,
            },
        },
    };

    const owl_option1 = {
        loop: true,
        margin: 10,
        nav: false,
        dots: false,
        autoplay: true,
        autoplayTimeout: 1,
        autoplayHoverPause: true,
        smartSpeed: 2000,
        animateOut: 'linear',
        animateIn: 'linear',
        responsive: {
            0: {
                items: 1,
                margin: 10,
            },
            361: {
                items: 1.5,
                margin: 10,
            },
            600: {
                items: 1.5,
                margin: 10,
            },
            700: {
                items: 2.5,
                margin: 10,
            },
            1000: {
                items: 4.5,
                margin: 10,
            },
            1600: {
                items: 5,
                margin: 10,
            },
            1900: {
                items: 7,
                margin: 10,
            },
        },
    };

    return (
        <>
            <section className="partners-section">
                <div className="top-left-text">
                    <p ref={textRef1}>02. AUDITORS | Partners</p>
                </div>
                <div className="custom-container">
                    <div className="parent">
                        <div className="left-side">
                            <div className="main-content">
                                <h4 ref={textRef2}>Integration runs in our code</h4>
                                <p ref={textRef3}>DOP's strategy prioritizes collaboration and interoperability, aspiring to deliver our protocol to the masses through seamless integration with leading wallets and dApps.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bottom-slider bottom-slider1">
                    <div class="owl_option">
                        <OwlCarousel
                            className="owl-theme"
                            {...owl_option}
                        >
                            <a href="https://hacken.io/audits/dop/" target='_blank'>
                                <div className="single-item">
                                    <p>Audited by</p>
                                    <img src="\assets\landing\hacken.svg" alt="img" className='img-fluid' />
                                </div>
                            </a>
                            <a href="https://www.bitcoin.com/" target='_blank'>
                                <div className="single-item">
                                    <p>Native Integrations</p>
                                    <img src="\assets\landing\bitcoin.svg" alt="img" className='img-fluid' />
                                </div>
                            </a>
                            <a href="https://zk.me/" target='_blank'>
                                <div className="single-item">
                                    <p>Core Collaborators</p>
                                    <img src="\assets\landing\zkme.svg" alt="img" className='img-fluid' />
                                </div>
                            </a>
                            <a href="https://www.chainalysis.com/" target='_blank'>
                                <div className="single-item">
                                    <p>Core Collaborators</p>
                                    <img src="\assets\landing\chain.svg" alt="img" className='img-fluid' />
                                </div>
                            </a>
                            <a href="https://polygon.technology/" target='_blank'>
                                <div className="single-item">
                                    <p>Official Collaboration</p>
                                    <img src="\assets\landing\polygon.svg" alt="img" className='img-fluid' />
                                </div>
                            </a>
                            <a href="https://www.okx.com/" target='_blank'>
                                <div className="single-item">
                                    <p>wallet integration </p>
                                    <img src="\assets\landing\okx.svg" alt="img" className='img-fluid' />
                                </div>
                            </a>
                            <a href="https://www.okx.com/xlayer" target='_blank'>
                                <div className="single-item">
                                    <p>Official Collaboration</p>
                                    <img src="\assets\landing\xlayer.svg" alt="img" className='img-fluid' />
                                </div>
                            </a>
                            <a href="https://hacken.io/audits/dop/" target='_blank'>
                                <div className="single-item">
                                    <p>Audited by</p>
                                    <img src="\assets\landing\hacken.svg" alt="img" className='img-fluid' />
                                </div>
                            </a>
                            <a href="https://www.bitcoin.com/" target='_blank'>
                                <div className="single-item">
                                    <p>Native Integrations</p>
                                    <img src="\assets\landing\bitcoin.svg" alt="img" className='img-fluid' />
                                </div>
                            </a>
                            <a href="https://zk.me/" target='_blank'>
                                <div className="single-item">
                                    <p>Core Collaborators</p>
                                    <img src="\assets\landing\zkme.svg" alt="img" className='img-fluid' />
                                </div>
                            </a>
                            <a href="https://www.chainalysis.com/" target='_blank'>
                                <div className="single-item">
                                    <p>Core Collaborators</p>
                                    <img src="\assets\landing\chain.svg" alt="img" className='img-fluid' />
                                </div>
                            </a>
                            <a href="https://polygon.technology/" target='_blank'>
                                <div className="single-item">
                                    <p>Official Collaboration</p>
                                    <img src="\assets\landing\polygon.svg" alt="img" className='img-fluid' />
                                </div>
                            </a>
                            <a href="https://www.okx.com/" target='_blank'>
                                <div className="single-item">
                                    <p>wallet integration </p>
                                    <img src="\assets\landing\okx.svg" alt="img" className='img-fluid' />
                                </div>
                            </a>
                            <a href="https://www.okx.com/xlayer" target='_blank'>
                                <div className="single-item">
                                    <p>Official Collaboration</p>
                                    <img src="\assets\landing\xlayer.svg" alt="img" className='img-fluid' />
                                </div>
                            </a>
                            <a href="https://hacken.io/audits/dop/" target='_blank'>
                                <div className="single-item">
                                    <p>Audited by</p>
                                    <img src="\assets\landing\hacken.svg" alt="img" className='img-fluid' />
                                </div>
                            </a>
                            <a href="https://www.bitcoin.com/" target='_blank'>
                                <div className="single-item">
                                    <p>Native Integrations</p>
                                    <img src="\assets\landing\bitcoin.svg" alt="img" className='img-fluid' />
                                </div>
                            </a>
                            <a href="https://zk.me/" target='_blank'>
                                <div className="single-item">
                                    <p>Core Collaborators</p>
                                    <img src="\assets\landing\zkme.svg" alt="img" className='img-fluid' />
                                </div>
                            </a>
                            <a href="https://www.chainalysis.com/" target='_blank'>
                                <div className="single-item">
                                    <p>Core Collaborators</p>
                                    <img src="\assets\landing\chain.svg" alt="img" className='img-fluid' />
                                </div>
                            </a>
                            <a href="https://polygon.technology/" target='_blank'>
                                <div className="single-item">
                                    <p>Official Collaboration</p>
                                    <img src="\assets\landing\polygon.svg" alt="img" className='img-fluid' />
                                </div>
                            </a>
                            <a href="https://www.okx.com/" target='_blank'>
                                <div className="single-item">
                                    <p>wallet integration </p>
                                    <img src="\assets\landing\okx.svg" alt="img" className='img-fluid' />
                                </div>
                            </a>
                            <a href="https://www.okx.com/xlayer" target='_blank'>
                                <div className="single-item">
                                    <p>Official Collaboration</p>
                                    <img src="\assets\landing\xlayer.svg" alt="img" className='img-fluid' />
                                </div>
                            </a>

                        </OwlCarousel>
                    </div>
                </div>
                <div className="bottom-slider mt-0 d-none bottom-slider-mobileblock"  >
                    <div class="owl_option">
                        <OwlCarousel
                            className="owl-theme"
                            {...owl_option1}
                        >
                             <a href="https://hacken.io/audits/dop/" target='_blank'>
                                <div className="single-item">
                                    <p>Audited by</p>
                                    <img src="\assets\landing\hacken.svg" alt="img" className='img-fluid' />
                                </div>
                            </a>
                            <a href="https://www.bitcoin.com/" target='_blank'>
                                <div className="single-item">
                                    <p>Native Integrations</p>
                                    <img src="\assets\landing\bitcoin.svg" alt="img" className='img-fluid' />
                                </div>
                            </a>
                            <a href="https://zk.me/" target='_blank'>
                                <div className="single-item">
                                    <p>Core Collaborators</p>
                                    <img src="\assets\landing\zkme.svg" alt="img" className='img-fluid' />
                                </div>
                            </a>
                            <a href="https://www.chainalysis.com/" target='_blank'>
                                <div className="single-item">
                                    <p>Core Collaborators</p>
                                    <img src="\assets\landing\chain.svg" alt="img" className='img-fluid' />
                                </div>
                            </a>
                            <a href="https://polygon.technology/" target='_blank'>
                                <div className="single-item">
                                    <p>Official Collaboration</p>
                                    <img src="\assets\landing\polygon.svg" alt="img" className='img-fluid' />
                                </div>
                            </a>
                            <a href="https://www.okx.com/" target='_blank'>
                                <div className="single-item">
                                    <p>wallet integration </p>
                                    <img src="\assets\landing\okx.svg" alt="img" className='img-fluid' />
                                </div>
                            </a>
                            <a href="https://www.okx.com/xlayer" target='_blank'>
                                <div className="single-item">
                                    <p>Official Collaboration</p>
                                    <img src="\assets\landing\xlayer.svg" alt="img" className='img-fluid' />
                                </div>
                            </a>
                            <a href="https://hacken.io/audits/dop/" target='_blank'>
                                <div className="single-item">
                                    <p>Audited by</p>
                                    <img src="\assets\landing\hacken.svg" alt="img" className='img-fluid' />
                                </div>
                            </a>
                            <a href="https://www.bitcoin.com/" target='_blank'>
                                <div className="single-item">
                                    <p>Native Integrations</p>
                                    <img src="\assets\landing\bitcoin.svg" alt="img" className='img-fluid' />
                                </div>
                            </a>
                            <a href="https://zk.me/" target='_blank'>
                                <div className="single-item">
                                    <p>Core Collaborators</p>
                                    <img src="\assets\landing\zkme.svg" alt="img" className='img-fluid' />
                                </div>
                            </a>
                            <a href="https://www.chainalysis.com/" target='_blank'>
                                <div className="single-item">
                                    <p>Core Collaborators</p>
                                    <img src="\assets\landing\chain.svg" alt="img" className='img-fluid' />
                                </div>
                            </a>
                            <a href="https://polygon.technology/" target='_blank'>
                                <div className="single-item">
                                    <p>Official Collaboration</p>
                                    <img src="\assets\landing\polygon.svg" alt="img" className='img-fluid' />
                                </div>
                            </a>
                            <a href="https://www.okx.com/" target='_blank'>
                                <div className="single-item">
                                    <p>wallet integration </p>
                                    <img src="\assets\landing\okx.svg" alt="img" className='img-fluid' />
                                </div>
                            </a>
                            <a href="https://www.okx.com/xlayer" target='_blank'>
                                <div className="single-item">
                                    <p>Official Collaboration</p>
                                    <img src="\assets\landing\xlayer.svg" alt="img" className='img-fluid' />
                                </div>
                            </a>
                            <a href="https://hacken.io/audits/dop/" target='_blank'>
                                <div className="single-item">
                                    <p>Audited by</p>
                                    <img src="\assets\landing\hacken.svg" alt="img" className='img-fluid' />
                                </div>
                            </a>
                            <a href="https://www.bitcoin.com/" target='_blank'>
                                <div className="single-item">
                                    <p>Native Integrations</p>
                                    <img src="\assets\landing\bitcoin.svg" alt="img" className='img-fluid' />
                                </div>
                            </a>
                            <a href="https://zk.me/" target='_blank'>
                                <div className="single-item">
                                    <p>Core Collaborators</p>
                                    <img src="\assets\landing\zkme.svg" alt="img" className='img-fluid' />
                                </div>
                            </a>
                            <a href="https://www.chainalysis.com/" target='_blank'>
                                <div className="single-item">
                                    <p>Core Collaborators</p>
                                    <img src="\assets\landing\chain.svg" alt="img" className='img-fluid' />
                                </div>
                            </a>
                            <a href="https://polygon.technology/" target='_blank'>
                                <div className="single-item">
                                    <p>Official Collaboration</p>
                                    <img src="\assets\landing\polygon.svg" alt="img" className='img-fluid' />
                                </div>
                            </a>
                            <a href="https://www.okx.com/" target='_blank'>
                                <div className="single-item">
                                    <p>wallet integration </p>
                                    <img src="\assets\landing\okx.svg" alt="img" className='img-fluid' />
                                </div>
                            </a>
                            <a href="https://www.okx.com/xlayer" target='_blank'>
                                <div className="single-item">
                                    <p>Official Collaboration</p>
                                    <img src="\assets\landing\xlayer.svg" alt="img" className='img-fluid' />
                                </div>
                            </a>

                        </OwlCarousel>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Partners
